import React, { useState } from "react";
import "./../rfq.css";
import AWN from "awesome-notifications";
import axios from "axios";
import { baseUrlContact } from "./../../httpBaseUrl.js";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Button from "@mui/material/Button";

const ContactForm = () => {
  const [rfqInfo, setRfqInfo] = useState("");
  const [prefixPhone, setPrefixPhone] = useState();

  if (
    localStorage.getItem("rfqInfo") === null ||
    localStorage.getItem("rfqInfo") === "undefined"
  ) {
    localStorage.setItem("rfqInfo", JSON.stringify("rfqInfo"));
  } else {
    var _rfqInfo = JSON.parse(localStorage.getItem("rfqInfo"));
  }

  let globalOptions = {
    positio: "bottom- right",
    maxNotifications: 3,
    animationDuration: 200,
    durations: 100,
  };

  let notifier = new AWN(globalOptions);

  function sendRfqData(e) {
    setRfqInfo(JSON.parse(localStorage.getItem("rfqInfo")));

    let dataParts = [];
    let elements = document.getElementsByClassName("bodyTable");
    let firstError = true;
    let rfqDataValidation = true;

    for (let i = 0; i < elements.length; i++) {
      let inputs = elements[i].querySelectorAll("input");

      for (let j = 0; j < inputs.length; j++) {
        inputs[j].classList.remove("invalid");
        if (inputs[j].value === "" || inputs[j].value === null) {
          rfqDataValidation = false;
          inputs[j].classList.add("invalid");

          if (firstError) {
            inputs[j].focus();
            firstError = false;
          }
        }
      }

      if (rfqDataValidation) {
        dataParts.push({
          nsn: inputs[0].value,
          mfgsku: inputs[1].value,
          item_name: inputs[2].value,
          quantity: Number(inputs[3].value),
        });
      }
    }

    const firstNameEl = document.getElementById("firstNameInput");
    const lastNameEl = document.getElementById("lastNameInput");
    const emailEl = document.getElementById("emailInput");
    const companyTypeEl = document.getElementById("companyTypeInput");
    const timeLineEl = document.getElementById("timelineInput");
    const companyEl = document.getElementById("companyInput");
    const commentEl = document.getElementById("commentsInput");

    const inputFormEl = [
      firstNameEl,
      lastNameEl,
      emailEl,
      companyTypeEl,
      timeLineEl,
      companyEl,
    ];

    inputFormEl.forEach((inputEl) => {
      if (inputEl.value === "") {
        inputEl.classList.add("invalid");
      } else {
        inputEl.classList.remove("invalid");
      }
    });

    const emailRegex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    const valEmail = emailRegex.test(emailEl.value);
    if (!valEmail) {
      emailEl.classList.add("invalid");
    }

    const invalidExist = document.getElementsByClassName("invalid").length > 0;
    let phoneToSend = prefixPhone.toString().slice(1);
    let dataInputs = {
      first_name: firstNameEl.value,
      last_name: lastNameEl.value,
      email: emailEl.value,
      company_type: companyTypeEl.value,
      time_line: timeLineEl.value,
      phone: phoneToSend,
      company: companyEl.value,
      comments: commentEl.value,
      quoteDetail: dataParts,
    };

    var url = baseUrlContact + "/rfq/newrfq";

    if (!rfqDataValidation) {
      e.preventDefault();
      notifier.alert("Review Input Fields", { durations: { alert: 0 } });
    }

    if (!invalidExist) {
      e.preventDefault();
      notifier.success("Request sent successfully", {
        durations: { success: 0 },
      });
      firstNameEl.value = "";
      lastNameEl.value = "";
      emailEl.value = "";
      companyTypeEl.value = "";
      timeLineEl.value = "";
      companyEl.value = "";
      commentEl.value = "";
      setPrefixPhone(null); // Reset the phone input

      axios
        .post(baseUrlContact + "rfq/newrfq", dataInputs)
        .then(function (response) {})
        .catch(function (error) {
          notifier.modal("<center>Error</center>");
        });
    } else if (rfqDataValidation) {
      notifier.alert("Review Input Fields", { durations: { alert: 0 } });
    }
  }

  return (
    <div>
      <div className="ContactFormComp">
        <h1 className="contactTitle">Contact Information</h1>
        <div className="Contact">
          <div className="firstChild">
            <p>
              Please enter your contact details, and we will provide you with a
              quotation for pricing and delivery within 24 to 48 hours in most
              cases. <br />
              <br /> If you have any target pricing, certification levels, or
              any other information that would be helpful to us in providing you
              a quote, please use the comments & instructions section of our
              submission form.
              <br />
              <br /> Every opportunity to quote our clients is valued by our
              team. Please alert us of the urgency and timeline of your intent
              to purchase, so that we may prioritize your request accordingly.
              <br />
              <br />
              <br /> Your sales representative will contact you if we need any
              other information. <br />
              <br />
              Thank you for the opportunity!
            </p>
          </div>
          <form onSubmit={(e) => sendRfqData(e)} style={{ width: "100%" }}>
            <div className="lastChild">
              <div className="contentInputs">
                <label style={{ color: 'black' }} htmlFor="firstNameInput">FIRST NAME *</label>
                <input
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please Enter Your First Name")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  type="text"
                  required
                  id="firstNameInput"
                />
              </div>
              <div className="contentInputs">
                <label style={{ color: 'black' }} htmlFor="lastNameInput">LAST NAME *</label>
                <input
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please Enter Your Last Name")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  type="text"
                  required
                  id="lastNameInput"
                />
              </div>
              <div className="contentInputs">
                <label style={{ color: 'black' }} htmlFor="emailInput">EMAIL *</label>
                <input
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please Enter Your Email")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  type="email"
                  required
                  id="emailInput"
                  value={_rfqInfo.rfqAdress}
                />
              </div>
              <div className="contentInputs">
                <label style={{ color: 'black' }} htmlFor="companyTypeInput">COMPANY TYPE *</label>
                <select required id="companyTypeInput">
                  <option value="" disabled defaultValue>
                    Select
                  </option>
                  <optgroup label="Manufacturer">
                    <option value="OEM (US)">OEM (US)</option>
                    <option value="Contract Manufacturer (US)">
                      Contract Manufacturer (US)
                    </option>
                    <option value="Foreign Manufacturer">
                      Foreign Manufacturer
                    </option>
                  </optgroup>
                  <option value="US Military">US Military</option>
                  <optgroup label="Government">
                    <option value="U.S. Federal Government">
                      U.S. Federal Government
                    </option>
                    <option value="U.S State & Local Government">
                      U.S State & Local Government
                    </option>
                    <option value="Foreign Government">
                      Foreign Government
                    </option>
                  </optgroup>
                  <optgroup label="Service">
                    <option value="Service Organization (MRO)">
                      Service Organization (MRO)
                    </option>
                    <option value="Service Provider">Service Provider</option>
                    <option value="Professional Organization">
                      Professional Organization
                    </option>
                    <option value="Commercial Airline">
                      Commercial Airline
                    </option>
                  </optgroup>
                  <optgroup label="Other">
                    <option value="Bank or College">Bank or College</option>
                    <option value="Retail Establishment">
                      Retail Establishment
                    </option>
                  </optgroup>
                </select>
              </div>
              <div className="contentInputs">
                <label style={{ color: 'black' }} htmlFor="timelineInput">TIMELINE *</label>
                <select required id="timelineInput">
                  <option value="" disabled defaultValue>
                    Select
                  </option>
                  <option value="Immediate Purchase">Immediate Purchase</option>
                  <option value="1-2 Weeks">1-2 Weeks</option>
                  <option value="3 weeks+">3 weeks+</option>
                  <option value="Bid">Bid</option>
                </select>
              </div>
              <div className="contentInputs">
                <label style={{ color: 'black' }} htmlFor="phoneInput">PHONE *</label>
                <PhoneInput
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  value={prefixPhone}
                  onChange={setPrefixPhone}
                  id="phoneInput"
                />
              </div>
              <div className="contentInputs2">
                <label style={{ color: 'black' }} htmlFor="companyInput">COMPANY *</label>
                <input
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Please Enter Your Company Name")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  type="text"
                  required
                  id="companyInput"
                />
              </div>
              <div className="contentInputs3">
                <label style={{ color: 'black' }} htmlFor="commentsInput">ADDITIONAL COMMENTS AND INSTRUCTION</label>
                <textarea type="text" id="commentsInput" />
              </div>
              <button
                type="submit"
                className="Submit"
                onClick={(e) => sendRfqData(e)}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
